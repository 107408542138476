.campaign-settings-block {
  overflow-x: auto;
}

.campaign-settings-card-block-text-settings {
  margin-left: 19px !important;
}

.campaign-settings-card {
  min-width: 500px;
  max-height: 600px;
  margin-bottom: 15px;
}

.campaign-settings-card-header {
  background-color: #e7edee;
}

.campaign-settings-card-block {
  min-height: 135px;
  overflow: auto;
  text-align: left;
  padding: 10px;
}

.campaign-settings-card-block-name {
  margin-bottom: 10px;
  margin-left: 13px;
  font-size: 18px;
  font-weight: 400;
}

.campaign-settings-card-block-other-row {
  margin-bottom: 20px;
  margin-left: 14px;
}

.campaign-settings-card-block-navId {
  margin-left: auto;
}

.campaign-settings-card-block-set-regions-btn {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
}

.campaign-settings-card-block-set-regions-btn:hover {
  color: #0056b3;
  text-decoration: underline;
}

.campaign-settings-card-block-list-regions {
  margin-bottom: 20px;
  max-width: 735px;
  text-align: justify;
}

.campaign-settings-card-block-prompt {
  line-height: 10px;
  font-size: 12px;
  margin-left: 21px;
}

.campaign-settings-card-block-input-name-block {
  margin-left: 15px;
}

.campaign-settings-card-block-input-name {
  max-width: 400px;
}

.campaign-settings-card-block-settings {
  margin-bottom: 20px;
}

/* расписание мониторинга */

.campaign-settings-autostart-text-1 {
  margin-left: 13px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 18px;
}

.campaign-settings-autostart-text-2 {
  margin-left: 25px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 18px;
}

.campaign-settings-autostart {
  margin-left: 15px;
}

.campaign-settings-autostart-time-block {
  max-width: 810px;
  display: flex;
  border-bottom: #bcbdbd solid;
  border-width: 0.5px;
  margin-bottom: 10px;
}

.campaign-settings-autostart-days {
  display: inline-block;
  padding-left: 4px;
  margin-bottom: 15px;
  margin-top: 5px;
  width: 30px;
}

.campaign-settings-autostart-time {
  max-width: 800px;
  display: inline-block;
  padding-left: 104px;
}

.auto-start-check-box {
  display: inline-block;
  padding-right: 15px;
  padding-bottom: 10px;
}

.dropdown-auto-start-check-box {
  display: inline-block;
}

.dropdown-auto-start-btn {
  font-size: 14px;
  width: 48px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 6px;
  padding-top: 6px;
  background-color: white !important;
  color: black !important;
}

.auto-start-dropdown-menu {
  max-height: 250px;
  font-size: 12px;
  overflow-x: auto;
  min-width: 45.66px;
  /* border-radius: 0%; */
}

.auto-start-dropdown-menu-btn {
  padding-left: 5px;
  padding-right: 5px;
}

.auto-start-btn-add {
  /* height: 29px; */
  margin-left: 15px;
  font-size: 0.5rem;
  background-color: #6ca779;
  border-color: #6ca779;
}

.auto-start-btn-add-none-items {
  margin-left: 0px !important;
}

.auto-start-btn-del {
  /* height: 29px; */
  font-size: 0.5rem;
  margin-left: 5px;
  background-color: #bf636c;
  border-color: #bf636c;
}

/* .auto-start-btn-del-svg {
  font-size: 10px;
}

.auto-start-btn-add-svg {
  font-size: 10px;
} */
