.content-wrapper-reports {
  /* background:blue; */
  padding: 15px 25px 15px 25px;
  max-width: 1200px;
  /* max-width: 100%; */
}

.table-css-reports {
  font-size: 14px;
  outline: 1px solid white;
}

.reports-navigation-active-page {
  background: #e7edee;
}

.reports-create-task-btn-navigation {
  margin-left: 40px;
  font-weight: 400 !important;
}

.reports-create-task-btn {
  font-weight: 400 !important;
}

.reports-download-btn {
  color: #6ca779;
  border-color: #6ca779;
  font-size: 12px;
}

.reports-some-td {
  min-width: 150px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(23 162 184 / 5%);
}

/* start - для больше чем width: 992px */
@media (min-width: 992px) {
  .content-wrapper-reports {
    margin-left: 230px;
  }
}
/* end - для больше чем width: 992px */

/* start - до width: 850px */
@media (max-width: 850px) {
  .table-css-reports {
    display: block;
    overflow-x: auto;
  }
}
/* end - до width: 850px */

/* start - от 992 до 1080 */
@media (min-width: 992px) and (max-width: 1080px) {
  .table-css-reports {
    display: block;
    overflow-x: auto;
  }
}
/* end - от 992 до 1080 */

/* start - поиск кампании */
.header-report-position {
  display: flex;
  flex-wrap: wrap;
}

.header-report-btn {
  padding-right: 15px;
  padding-bottom: 15px;
}

.header-report {
  flex-grow: 1;
  font-size: 1.5rem;
  padding-bottom: 15px;
}

.header-report-btn button {
  background-color: #6ca779;
  border-color: #6ca779;
  padding-bottom: 5px;
  padding-top: 5px;
}
/* end - поиск кампании */

.reports-card-block-price-btn-download {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
}

.reports-card-block-price-btn-download:hover {
  color: #0056b3;
  text-decoration: underline;
}

.reports-table-td {
  border-right: 1px solid #dee2e6 !important;
  white-space: nowrap; /* Отменяем перенос текста */
}