.content-wrapper-campaigns {
  /* background:blue; */
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 1200px;
}

.campaign-card-header {
  background-color: #e7edee;
}

.campaign-card-block-nav-link {
  color: #495057;
}

.campaign-card-block-nav-link:hover {
  color: #495057 !important;
}

.campaign-card {
  margin-bottom: 15px;
}

.campaign-card-block-main {
  text-align: left;
  padding: 10px;
}

.campaign-card-block-price {
  text-align: left;
  padding: 10px;
}

.campaign-card-block-autoStart {
  text-align: left;
  padding: 10px;
}

.card-block-off {
  display: none;
}

.campaign-card-block-navId {
  margin-left: auto;
}

.page-link {
  color: #343a40;
}

.campaign-card-block-name {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 13px;
  font-size: 18px;
  font-weight: 400;
}

.campaign-card-block-price-name {
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 13px;
  font-size: 18px;
  /* font-weight: 500; */
}

.campaign-card-block-price-name > span {
  font-weight: 400;
}

.campaign-card-block-price-status {
  font-size: 12px;
  margin-left: 5px;
}

.campaign-card-block-price-btn-download {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
}

.campaign-card-block-price-btn-download:hover {
  color: #0056b3;
  text-decoration: underline;
}

.campaign-card-block-btn-clear-input-text {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
}

.campaign-card-block-btn-clear-input-text:hover {
  color: #0056b3;
  text-decoration: underline;
}

.input-search-campaigns {
  max-height: 36px;
  height: 36px;
}

.campaign-card-block-price-btn-create-task {
  margin-left: 20px;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
}

.campaign-card-block-price-btn-create-task-first {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
}

.campaign-card-block-price-btn-create-task:hover {
  color: #0056b3;
  text-decoration: underline;
}

.campaign-card-block-price-btn-create-task-first:hover {
  color: #0056b3;
  text-decoration: underline;
}

.campaign-card-block-price-btn-match-columns {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
  display: inline-block;
}

.campaign-card-block-price-btn-match-columns:hover {
  color: #0056b3;
  text-decoration: underline;
}

.campaign-card-block-price-btn-upload {
  margin-left: 20px;
}

.campaign-card-block-other-row {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 13px;
}

.campaign-btn-links {
  margin-left: 13px;
}

.campaign-card-block-btn-settings {
  margin: 0px;
  padding: 0px;
  /* margin-bottom: 5px; */
}

.campaign-card-not-found {
  margin-top: 15px;
}

.campaign-card-all-blocks {
  overflow-x: auto;
}

.campaign-card-block {
  min-width: 500px;
}

/* start - поиск кампании */
.search-campaign-position {
  display: flex;
  flex-wrap: wrap;
}

.search-campaign-btn {
  padding-right: 15px;
  padding-bottom: 15px;
}

.search-campaign {
  flex-grow: 1;
}

.search-campaign-btn button {
  background-color: #6ca779;
  border-color: #6ca779;
  padding-bottom: 5px;
  padding-top: 5px;
}
/* end - поиск кампании */

/* start - цвета индикатора */
.indicator-finished {
  color: #6ca779;
}

.indicator-file-upload {
  color: #196b9c;
}

.indicator-collecting {
  color: #196b9c;
}

.indicator-column-matching {
  color: #f5d328;
}

.indicator-error {
  color: #c14d59;
}
/* end - цвета индикатора */

/* start - для больше чем width: 992px */
@media (min-width: 992px) {
  .content-wrapper-campaigns {
    margin-left: 230px;
  }
}
/* end - для больше чем width: 992px */

.campaign-card-header-radius {
  border-radius: 0px !important;
}

.campaign-card-block-times {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 13px;
  max-width: 700px;
}

.campaign-card-block-days {
  width: 30px;
}
