/* start - для меньше чем width: 992px */

.navbar-div-logout-selected-user {
  margin-left: 20px;
  padding: 10px;
}

.navbar-btn-logout-selected-user {
  color: white;
  cursor: pointer;
}

.navbar-brand:hover {
  cursor: pointer;
}

.nav-btn-right {
  margin-left: auto;
}

.grow:hover {
  -webkit-transform: 0;
  -ms-transform: 0;
  transform: 0;
}

.btnMenu {
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  border: none;
  padding: 10px;
  margin-left: 20px;
}

.btnMenu:hover {
  background-color: transparent;
}

.btnMenuActive {
  background-color: transparent;
  /* background-color: rgb(255 255 255 / 9%); */
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  border: none;
  padding: 10px;
  margin-left: 20px;
  /* font-weight: 800; */
}

.menuActiveColor {
  background-color: rgb(255 255 255 / 9%);
  max-width: 250px;
}

.btnMenuActive:hover {
  background-color: transparent;
}

.btnMenuActive:active {
  position: relative;
  top: 1px;
}

.navbar-btn-logout {
  margin-top: 20px;
}

.btnMenu-top-right:active {
  position: relative;
  top: 1px;
}
/* end - для меньше чем width: 992px */

/* start - для больше чем width: 992px */
@media (min-width: 992px) {
  .sidenav {
    position: absolute;
    top: 0;
    left: 0;
    width: 230px;
    height: calc(100vh - 56px);
    min-height: 840px;
    margin-top: 56px;
    background: #343a40;
    box-sizing: border-box;
    /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
    padding-top: 20px;
  }

  .navbar-div-logout-selected-user {
    margin-left: 0px;
    padding: 0px;
  }

  .navbar-expand-lg .sidenav {
    flex-direction: column;
  }

  .grow:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }

  .btnMenu-top-right {
    padding: 0px;
  }
}
/* end - для больше чем width: 992px */
