.users-not-found {
  margin-top: 15px;
}

.users-btn-clear-input-text {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none !important;
}

.users-btn-clear-input-text:hover {
  color: #0056b3;
  text-decoration: underline;
}

.search-users-position {
  display: flex;
  flex-wrap: wrap;
}

.search-users {
  flex-grow: 1;
}

.input-search-users {
  max-height: 35px;
  height: 35px;
}

.users-btn-reload {
  padding: 0px !important;
}

.users-btn-reload:focus {
  box-shadow: none !important;
}

.users-table-td {
  border-right: 1px solid #dee2e6 !important;
  white-space: nowrap; /* Отменяем перенос текста */
}

.users-btn-watch {
  padding: 0px 1.5px !important;
  font-size: 10px !important;
  color: white !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  height: 15px !important;
}

.users-watch-svg {
  margin-top: -1.5px;
}

.users-btn-watch:hover {
  color: white !important;
  background-color: #138394 !important;
  border-color: #138394 !important;
}

.users-btn-watch:active {
  color: white !important;
  background-color: #138394 !important;
  border-color: #138394 !important;
}

.users-btn-watch:focus {
  box-shadow: none !important;
}

.users-content-wrapper {
  /* background:blue; */
  padding: 15px 25px 15px 25px;
  max-width: 1200px;
  /* max-width: 100%; */
}

.users-table-css {
  font-size: 14px;
  outline: 1px solid white;
}

/* start - для больше чем width: 992px */
@media (min-width: 992px) {
  .users-content-wrapper {
    margin-left: 230px;
  }
}
/* end - для больше чем width: 992px */

/* start - до width: 660px */
@media (max-width: 660px) {
  .users-table-css {
    display: block;
    overflow-x: auto;
  }
}
/* end - до width: 660px */

/* start - от 992 до 1067 */
@media (min-width: 992px) and (max-width: 1200px) {
  /* .users-table-css {
    display: block;
    overflow-x: auto;
  } */
}
/* end - от 992 до 1067 */
