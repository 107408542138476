.content-wrapper-all-reports {
  /* background:blue; */
  padding: 15px 25px 15px 25px;
  max-width: 1200px;
  /* max-width: 100%; */
}

.table-css-campaigns-reports {
  font-size: 14px;
  outline: 1px solid white;
}

/* start - для больше чем width: 992px */
@media (min-width: 992px) {
  .content-wrapper-all-reports {
    margin-left: 230px;
  }
}
/* end - для больше чем width: 992px */

/* start - до width: 950px */
@media (max-width: 950px) {
  .table-css-campaigns-reports {
    display: block;
    overflow-x: auto;
  }
}
/* end - до width: 950px */

/* start - от 992 до 1067 */
@media (min-width: 992px) and (max-width: 1200px) {
  .table-css-campaigns-reports {
    display: block;
    overflow-x: auto;
  }  
}
/* end - от 992 до 1067 */