.questions-card-header {
    font-size: 1.5rem;
    margin-bottom: 1px;
}

.questions-btn-header {
    padding-left: 2px !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.questions-video {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    display: flex;
}

.questions-video-item {
    flex-grow: 1;
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: transparent !important;
    font-weight: bolder !important;
    box-shadow: none !important;
}
