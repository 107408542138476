.content-wrapper-column-matching {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.content-wrapper-column-matching-message {
    padding: 50px;
}

.column-matching-skip-rows {
    margin-bottom: 10px;
}

.column-matching-skip-rows-input {
    max-width: 35px;
    display: inline-block;
}

.table-css {
    font-size: 14px;
    min-height: 300px;
    display: block;
    overflow-x: auto;
}

/* start - для больше чем width: 992px */
@media (min-width: 992px) {
    .content-wrapper-column-matching {
        margin-left: 230px;
    }

    .content-wrapper-column-matching-message {
        margin-left: 230px;
    }
}