.payment-methodRadios-form {
    margin: 0px !important;
    padding-top: 0px !important;
    /* width: 0px !important; */
}


.payment-methodRadios-form-check {
    margin-left: 15px;
    margin-bottom: 10px;
}

.btn-cashless-payment {
    padding: 0px;
    margin-top: 12px;
}

.btn-cashless-payment:focus {
    box-shadow: none !important;
}

.btn-none-shadow:focus {
    box-shadow: none !important;
}