.modal {
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
}

.modal-dialog {
    margin: auto;
    top: 25%;
    padding: 25px;
}

.modal-btn-success {
    background-color: #6ca779;
    border-color: #6ca779;
}