.custom-btn-region-add {
    background-color: #6ca779;
    border-color: #6ca779;
}

.custom-btn-region-del {
    background-color: #c14d59;
    border-color: #c14d59;
}

.campaign-region-clear-button {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none !important;
  }
  
  .campaign-region-clear-button:hover {
    color: #0056b3;
    text-decoration: underline;
  }