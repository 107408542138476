body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

form {
  margin: 0 auto;
  padding-top: 5%;
  width: 350px;
}

.content-wrapper {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
}

.content-wrapper-message {
  padding: 50px;
}

/* start - для больше чем width: 992px */
@media (min-width: 992px) {
  .content-wrapper {
    margin-left: 230px;
    max-width: 1200px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .content-wrapper-message {
    margin-left: 230px;
    max-width: 1200px;
  }
}
/* end - для больше чем width: 992px */

.navbar-toggler:focus {
  box-shadow: none;
}

.btn-info {
  font-weight: 500;
}

.btn-second {
  float: right;
}

.bg-dark {
  background-color: #17a2b8 !important;
  min-height: 56px;
  /* max-height: 56px; */
}

.authheader {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

.newCompain {
  margin: auto;
  max-width: 50%;
}

.newCompainHeader {
  margin: auto;
  max-width: 50%;
  text-align: left;
  font-size: 2rem;
  margin-top: 40px;
}

.fileicon {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footerNewCompain {
  background-color: #17a2b8 !important;
  text-align: center;
}

.btn-link-footerfirstCompain {
  color: #fff !important;
}

.btnDisabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.cardHeader {
  font-size: 1.5rem;
  margin-bottom: 15px;
  /* margin-left: -2px; */
}

.cardFooter {
  text-align: center;
  padding: 12px;
}

.mainCard {
  margin-top: 40px;
}

.margin-bottom {
  margin-bottom: 0;
}

.margin-top {
  margin-top: 16px;
}

.headers-css {
  font-size: 14px;
  max-width: 160px;
  min-width: 160px;
  text-align: left;
}

.border-top-off {
  border-top: 0 !important;
}

.truncate-text {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  min-width: 200px;
}

.margin-top {
  margin-top: 0;
}

.custom-border {
  border: none;
}

.regionLabelLeft {
  margin-left: 5px;
}

.regionsScroll {
  overflow: auto;
  height: 400px;
}

.btn-trash {
  font-size: 0.5rem;
  margin-right: 15px;
}

.custom-border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
  position: static;
}

.search-region {
  min-height: 60px;
}

.searchInputRegion {
  width: 100%;
  margin: 0;
  padding: 8px;
  max-height: 60px;
}

.searchInputRegion input {
  padding-left: 15px;
}

.display-false {
  display: none;
}

.btn-save-regions {
  margin-top: 0 !important;
}

.search-region-value-title {
  min-height: 60px;
  padding-top: 17px;
  padding-left: 23px;
}

.bg-danger {
  background-color: #c14d59 !important;
}

.bg-success {
  background-color: #6ca779 !important;
}

.bg-warning {
  background-color: #daa917 !important;
}

.text-selection-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.pagination-flex-wrap {
  flex-wrap: wrap;
}

.indicator {
  font-size: 10px;
  margin-left: 5px;
  color: #888a8e;
}

.long-name {
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis; /* Многоточие */
  max-width: 600px;
}

.custom-btn-campaign-del {
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 3px;
  margin-left: 5px;
  font-size: 0.5rem;
  font-weight: bold;
}

.campaign-select {
  font-size: 24px;
  margin-bottom: 15px;
}

.campaign-select__header {
  margin-bottom: 20px;
}

.campaign-select__body {
  font-size: 16px;
  max-width: 500px;
  text-align: justify;
}

.campaign-select__body p {
  margin-bottom: 2rem;
}
.campaign-select__body div {
  margin-bottom: 20px;
}